<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">Search Result</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                Search Result
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="pt-7 pb-7 pt-md-10">
      <div class="container">
        <form class="shadow-sm rounded mb-8">
          <div class="input-group input-group-lg">
            <div class="input-group-prepend">
              <span class="input-group-text bg-light border-0 px-4">
                <i
                  class="fa fa-search text-muted font-size-18"
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            <input
              type="text"
              class="form-control bg-light border-0 form-control-lg"
              aria-label="Amount (to the nearest dollar)"
              placeholder="搜尋"
              required=""
              v-model="searchText"
            />
            <div class="input-group-append">
              <button
                type="button"
                class="btn shadow-none btn-lg btn-danger text-uppercase"
                @click="searchThisPage"
              >
                Search
              </button>
            </div>
          </div>
        </form>
        <h2 class="text-capitalize text-muted font-weight-bold mb-0">
          {{ searchResponse.length }} Search result for “{{ this.$route.query.q }}{{ searchText }}”
        </h2>
      </div>
    </section>

    <section class="pb-md-10 pb-6">
      <div class=" container">
        <div class="list-fullwidth">
          <div class="media media-list-view mb-5" v-for="item in searchResponse" :key="item.id">
            <div class="media-img">
              <img :src="item.banner" alt="Image">
            </div>
            <div class="media-body search-body">
              <h3>
                <router-link :to="`/article/${item.id}`" :class="`text-${item.themeClass}`">
                  {{ item.title }}
                </router-link>
              </h3>
              <ul class="list-unstyled d-flex text-muted mb-3">
                <li class="">
                  <span class="text-muted d-inline-block mr-3">
                    <i class="fa fa-user mr-2" aria-hidden="true"></i>{{ item.user_name }}
                  </span>
                </li>
                <li class="mr-3">
                  <span class="text-muted d-inline-block">
                    <i class="fa fa-clock-o mr-2" aria-hidden="true"></i>{{ item.published_at }}
                  </span>
                </li>
              </ul>
              <p class="text-justify mb-lg-2" v-html="item.content"></p>
              <div>
                <router-link :to="`/article/${item.id}`"
                  :class="`text-hover-${item.themeClass}`"
                  class="btn btn-link text-underline pl-0">
                  <i class="fa fa-angle-double-right mr-1" aria-hidden="true"></i> More
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'search',
  data () {
    return {
      searchText: '',
      searchResponse: []
    }
  },
  computed: {
    q () {
      return this.$route.query.q
    }
  },
  created () {
    const vm = this
    vm.search()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    search () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/search`

      vm.axios.post(api, {
        token: tokenGenerator(),
        keyword: vm.q,
        page: 1,
        pageSize: 10
      })
        .then((res) => {
          if (res.data.status !== '000') {
            alert('查無資料')
          } else {
            vm.searchResponse = res.data.content
            vm.searchResponse.forEach((item, index) => {
              const i = index + 1
              if (i % 6 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 6 === 2) {
                return (item.themeClass = 'success')
              } else if (i % 6 === 3) {
                return (item.themeClass = 'danger')
              } else if (i % 6 === 4) {
                return (item.themeClass = 'info')
              } else if (i % 6 === 5) {
                return (item.themeClass = 'purple')
              } else {
                return (item.themeClass = 'pink')
              }
            })
          }
        })
    },
    searchThisPage () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/search`

      vm.axios.post(api, {
        token: tokenGenerator(),
        keyword: vm.searchText,
        page: 1,
        pageSize: 10
      })
        .then((res) => {
          if (res.data.status !== '000') {
            alert('查無資料')
          } else {
            vm.searchResponse = res.data.content
            vm.searchResponse.forEach((item, index) => {
              const i = index + 1
              if (i % 6 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 6 === 2) {
                return (item.themeClass = 'success')
              } else if (i % 6 === 3) {
                return (item.themeClass = 'danger')
              } else if (i % 6 === 4) {
                return (item.themeClass = 'info')
              } else if (i % 6 === 5) {
                return (item.themeClass = 'purple')
              } else {
                return (item.themeClass = 'pink')
              }
            })
          }
        })
    }
  },
  watch: {
    q () {
      const vm = this
      vm.search()
    }
  }
}
</script>
